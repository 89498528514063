import React, { useEffect } from "react";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import OutBottomRender from "./OutBottomRender/OutBottomRender";
import "../UserPanel.scss";
import "./Library.scss";
import Gallery from "modules/Gallery/Gallery";
import { useLibrary, usePosts } from "OdevFetch";

const Library = () => {
  const { payload: libraryPayload, loading: libraryLoading } = useLibrary();
  const {
    payload: postPayload,
    loading: postLoading,
    refetch,
  } = usePosts({
    isLazy: true,
  });

  useEffect(() => {
    const uniqueEventIds = new Set();
    if (libraryPayload?.data) {
      libraryPayload.data.forEach((obj) => {
        uniqueEventIds.add(obj.event_id);
      });

      const uniqIds = Array.from(uniqueEventIds);
      refetch({
        query: {
          include: uniqIds,
        },
      });
    }
  }, [libraryLoading]);

  if (libraryLoading || !postPayload.length) {
    return <div>Loading</div>;
  }

  const mergePayloads = () => {
    postPayload.forEach((post) => {
      post.photos = libraryPayload.data.filter(
        (library) => library.event_id === post.id
      );
    });
    return postPayload;
  };

  const events = mergePayloads();

  return (
    <div className="subpage library-page">
      <div className="subpage-wrapper">
        <div className="subpage-header">
          <h1>Biblioteka</h1>
        </div>
        <div className="subpage-content">
          {events.map((event) => (
            <Gallery
              title={event.title}
              images={event.photos || []}
              imageConfig={(item) => {
                return {
                  src: `${process.env.REACT_APP_PHOTOS_PATH}/${item?.category_id}/${item?.category_id}_${item?.photo_id}.jpg`,
                  outBottomRender: () => (
                    <OutBottomRender author={event.author} price={150} />
                  ),
                };
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Library;
