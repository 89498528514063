import "./TwoColumns.scss";

const TwoColumns = ({ imagePath, imageAlt, children }) => {
  return (
    <div className="two-columns-component container">
      <div className="row">
        <div className="image col-12 col-lg-6">
          <img src={imagePath} alt={imageAlt} />
        </div>
        <div className="content col-12 col-lg-6">{children}</div>
      </div>
    </div>
  );
};

export default TwoColumns;
