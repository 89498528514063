import "./Button.scss";
import { NavLink } from "react-router-dom";

const Button = ({
  variant = "normal",
  navigate,
  iconName = "",
  children,
  onClick,
  type = "button"
}) => {
  if (navigate)
    return (
      <NavLink className={`link-button ${variant}`} to={navigate} onClick={onClick}>
        {iconName} {children}
      </NavLink>
    );

  return (
    <button className={variant} onClick={onClick} type={type}>
      {iconName} {children}
    </button>
  );
};

export default Button;
