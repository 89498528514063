import { useEffect, useState } from "react";
import Cart from "./Cart";
import { useCart, usePosts } from "OdevFetch";

const CartLoader = () => {
  const [events, setEvents] = useState([]);

  const {
    payload: cartPayload,
    loading: cartLoading,
    remove: cartRemove,
    refetch: cartRefetch,
  } = useCart();

  const {
    payload: postPayload,
    loading: postLoading,
    refetch: postRefetch,
  } = usePosts({
    isLazy: true,
  });

  // if cartLoading is finish, find posts
  useEffect(() => {
    if (!cartLoading) {
      const uniqueEventIds = new Set();
      if (cartPayload?.data) {
        cartPayload.data.forEach((obj) => {
          uniqueEventIds.add(obj.event_id);
        });

        const uniqIds = Array.from(uniqueEventIds);

        postRefetch({
          query: {
            include: uniqIds,
          },
        });
      }
    }
  }, [cartLoading]);

  // if post is loaded, set events
  useEffect(() => {
    if (!postLoading && !cartLoading) {
      postPayload.forEach((post) => {
        post.photos = cartPayload.data.filter(
          (cart) => cart.event_id === post.id
        );
      });
      setEvents(postPayload);
    }
  }, [postLoading]);

  const onRemoveHandler = async (item) => {
    await cartRemove({ id: item.id });
    await cartRefetch();
  };

  if (cartLoading || postLoading) {
    return (
      <div>
        <img className="loading" src="/loading.png" />
      </div>
    );
  }

  if (cartPayload?.data?.length == 0) {
    return <div>Koszyk jest pusty</div>;
  }

  return <Cart events={events} onRemove={onRemoveHandler} />;
};

export default CartLoader;
