import React, { useState } from 'react';
import './Search.scss'

const Search = ({ onSearch }) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    onSearch(searchText);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-component">
      <input
        type="text"
        className="search-input"
        placeholder="Search..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <div className="search-icon" onClick={handleSearch}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="27" viewBox="0 0 25 27" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8096 16.0107L25 24.7015L22.8702 26.9614L14.6798 18.2706C13.1504 19.4385 11.3065 20.1514 9.29102 20.1514C4.15952 20.1514 0 15.7377 0 10.2927C0 4.84772 4.15952 0.434082 9.29102 0.434082C14.4225 0.434082 18.582 4.84772 18.582 10.2927C18.582 12.4313 17.9102 14.3879 16.8096 16.0107ZM9.29102 3.46751C5.73185 3.46751 2.85878 6.51611 2.85878 10.2927C2.85878 14.0693 5.73185 17.1179 9.29102 17.1179C12.8502 17.1179 15.7233 14.0693 15.7233 10.2927C15.7233 6.51611 12.8502 3.46751 9.29102 3.46751Z" fill="#49454F"/>
        </svg>
      </div>
    </div>
  );
};

export default Search;
