export const passwordStrength = (password) => {
  const length = password.length >= 14;
  const capitalLetter = /[A-Z]/.test(password);
  const smallLetter = /[a-z]/.test(password);
  const specialSign = /[!@#$%^&*(),.?":;{}|<>]/.test(password);
  const numbers = /\d/.test(password);

  const pwdStrenth =
    length * 1.5 +
    capitalLetter * 1.2 +
    specialSign * 1.8 +
    smallLetter * 1.2 +
    numbers * 1.5;

  if (pwdStrenth < 3) {
    return "Słabe hasło.";
  }
  if (pwdStrenth > 3 && pwdStrenth < 5) {
    return "Średnie hasło.";
  }
  if (pwdStrenth > 5) {
    return "Silne hasło.";
  }
};