import React, { useState } from "react";
import Button from "components/Button/Button";
import "../UserPanel.scss";
import "./EditPanel.scss";
import { socialMedia } from "./config";
import { Formik, Form, Field } from "formik";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";

const EditPanel = () => {
  const initialValues = {
    username: "",
    email: "",
  };
  const submitHandler = (data) => {};

  return (
    <div className="subpage">
      <div className="subpage-wrapper">
        <div className="subpage-header">{/* <h1>Panel użytkownika</h1> */}</div>
        <div className="edit-panel-content">
          <div className="connect-apps">
            <div className="header-title">
              <h2>Połączenie z aplikacjami</h2>
            </div>
            <div className="social-media">
              {socialMedia.map((item) => (
                <div key={item.name} className="social-media-single">
                  <img src={item.src} alt={`${item.name} icon`} />
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="user-form">
            <HeaderTitle title="Dane konta" />
            <Formik initialValues={initialValues} onSubmit={submitHandler}>
              <Form>
                <div className="form-wrapper">
                  <div className="form">
                    <div className="form-group">
                      <label htmlFor="username">Username</label>
                      <Field
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Jane Doe"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Janedoe@gmail.com"
                        required
                      />
                    </div>
                  </div>
                  <div className="password">
                    <h3>Hasło</h3>
                    <Button variant="round">Zmień hasło</Button>
                  </div>
                </div>
                <div className="notifications">
                  <div className="header">
                    <HeaderTitle title="Notyfikacje" />
                  </div>
                  <div className="notifications-checkbox">
                    <label htmlFor="notification">
                      Chcę otrzymywać informacje o nowych zdjęciach itp.
                    </label>
                    <input type="checkbox" id="notification" />
                  </div>
                </div>
                <div className="button">
                  <Button variant="round" type="submit">
                    Zapisz
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPanel;
