import "../styles.scss";
import Button from "components/Button/Button";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { passwordStrength } from "helpers/form_validator";
import { useState } from "react";
import * as Yup from "yup";
import { useUsers } from "OdevFetch";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const { save } = useUsers();
  const initialValues = {
    email: "",
    password: "",
    repeatPassword: "",
  };

  const [pwdStrength, setPwdStrength] = useState(null);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Nieprawidłowy adres e-mail")
      .required("E-mail jest wymagany."),
    password: Yup.string()
      .required("Hasło jest wymagane.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
        "Hasło musi mieć co najmniej 8 znaków, wielką literę, małą literę i cyfrę. Dozwolone znaki specjalme: !@#$%^&*"
      )
      .test("custom-validation", "Słabe hasło.", (value) => {
        const strengthMessage = passwordStrength(value);
        setPwdStrength(strengthMessage);
        return true;
      }),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Hasła muszą być takie same.")
      .required("Hasła muszą być takie same."),
  });

  const submitHandler = async (data) => {
    const response = await save({ body: data });
    if (response.status === "success") {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  };

  return (
    <div className="section-wrapper">
      <div className="panel">
        <div className="login-register-header">
          <h1>PhotoRace</h1>
          <Button variant="fresh" navigate={-1}>
            Powrót
          </Button>
        </div>
        <div className="form-wrapper">
          <p>
            Nie masz jeszcze konta? Zarejestruj się w kilku prostch krokach.
          </p>
          <div className="form">
            <Formik
              initialValues={initialValues}
              onSubmit={(data) => submitHandler(data)}
              validationSchema={SignupSchema}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field placeholder="Email" id="email" name="email"></Field>
                  <ErrorMessage name="email" component="div" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Hasło</label>
                  <Field
                    placeholder="Hasło"
                    id="password"
                    name="password"
                    type="password"
                  ></Field>
                  <ErrorMessage name="password" component="div" />
                  {pwdStrength && <>{pwdStrength}</>}
                </div>
                <div className="form-group">
                  <label htmlFor="repeatPassword">Powtórz hasło</label>
                  <Field
                    placeholder="Hasło"
                    id="repeatPassword"
                    name="repeatPassword"
                    type="password"
                  ></Field>
                  <ErrorMessage name="repeatPassword" component="div" />
                </div>

                <div className="submit-wrapper">
                  <div className="submit-content">
                    <Button type="submit">Zarejestruj</Button>
                    <div>
                      <span>
                        Klikając przycisk zarejestruj akceptujesz nasz
                        regulamin.
                      </span>
                      <Button variant="fresh" navigate="regulamin">
                        Zobacz regulamin.
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
