import Button from "components/Button/Button";

const SelectedSummary = ({ selectedImages, onAddCart, onBuy }) => {
  return (
    <div className="selected-items">
      <h2>Zaznaczono {selectedImages.length} zdjęć</h2>
      <div className="btn-wrapper">
        <Button onClick={() => onAddCart(selectedImages)}>
          Dodaj do koszyka
        </Button>
        <Button onClick={() => onBuy(selectedImages)}>Kup teraz</Button>
      </div>
    </div>
  );
};

export default SelectedSummary;
