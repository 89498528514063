import React from 'react';
import './HeaderTitle.scss';

const HeaderTitle = ({ title }) => {
    return (
        <div className="header-title">
            <h2>{title}</h2>
        </div>
    );
};

export default HeaderTitle;