import { useEffect, useState } from "react";
import { fetchSetting } from "./fetchConfig";

export const useQuery = ({ endpoint, isLazy }) => {
  const [state, setState] = useState({
    loading: isLazy ? false : true,
    payload: null,
  });

  const preparedHeader = () => {
    if (!endpoint.includes("posts") && !endpoint.includes("categories")) {
      return {
        ...fetchSetting.headers,
        ["Access-Token"]: `${sessionStorage.getItem("accessToken")}`,
      };
    }
    return { ...fetchSetting.headers };
  };

  const fetchPayload = async (payload) => {
    const preparedEndpoint = !payload?.endpoint ? endpoint : payload.endpoint;
    await fetch(preparedEndpoint, {
      ...fetchSetting,
      method: "GET",
      headers: preparedHeader(),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.status && res.message) {
          document.showAlert(res.message, res.status);
        }

        setState({
          ...state,
          payload: res,
          loading: false,
        });
      });
  };

  const refetch = (payload) => {
    setState({ ...state, loading: true });
    fetchPayload(payload);
  };

  useEffect(() => {
    if (!isLazy) {
      fetchPayload();
    }
  }, []);

  return {
    ...state,
    refetch,
  };
};
