export const GALLERY = [
  {
    id: 1,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",
    title: "Test title #1",
    author: "Jon Doe",
    category: "Kolarstwo torowe",
    price: 240.0,
  },
  {
    id: 2,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",
    config: {
      title: "Test title #2",
      author: "Jon Doe",
      // category: "Kolarstwo górskie",
    },
    price: 240.0,
  },
  {
    id: 3,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",
    config: {
      title: "Test title #3",
      author: "Jon Doe",
      category: "Kolarstwo szosowe",
    },
    price: 240.0,
  },
  {
    id: 4,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",
    config: {
      title: "Test title #4",
      author: "Jon Doe",
      category: "Kolarstwo BMX",
    },
    price: 240.0,
  },
  {
    id: 5,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",
    config: {
      title: "Test title #5",
      author: "Jon Doe",
      category: "Kolarstwo downhillowe",
    },
  },
  {
    id: 6,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",

    config: {
      title: "Test title #6",
      author: "Jon Doe",
      category: "Kolarstwo miejskie",
    },
  },
  {
    id: 7,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    alt: "test alt",
    config: {
      title: "Test title #7",
      author: "Jon Doe",
      category: "Kolarstwo cyklokrosowe",
    },
  },
  {
    id: 8,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    config: {
      title: "Test title #8",
      author: "Jon Doe",
      category: "Kolarstwo torowe",
    },
  },
  {
    id: 9,
    src: "https://photorace-api.odev.pl/zdjecia/another_event/another_event_2.jpg",
    config: {
      title: "Test title #9",
      author: "Jon Doe",
      category: "Kolarstwo torowe",
    },
  },
];
