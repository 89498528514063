import "./Contact.scss";
import SmallHeader from "modules/SmallHeader/SmallHeader";

const Contact = () => {

  return (
    <div className="contact-page">
      <SmallHeader />
      <div className="contact-wrapper">
        <h2>Kontakt</h2>
      </div>
    </div>
  )
};

export default Contact;