import SmallHeader from "modules/SmallHeader/SmallHeader";
import PhotoPresentator from "modules/PhotoPresentator/PhotoPresentator";

import Gallery from "modules/Gallery/Gallery";

import "./SinglePost.scss";

import { useNavigate, useParams } from "react-router-dom";
import { usePost } from "OdevFetch";

const SinglePost = () => {
  const navigate = useNavigate();
  const { eventId, photoId } = useParams();
  const { payload, loading } = usePost({ id: eventId });

  if (loading) {
    return <div>Loading</div>;
  }

  const photos = [];
  for (let i = 1; i <= payload?.image_count; i++) {
    photos.push({ ...payload, photo_id: i });
  }

  return (
    <div className="single-post-page">
      <SmallHeader />
      <div className="single-post-wrapper">
        <PhotoPresentator
          post={payload}
          photoId={photoId || 1}
          onMoveItem={(newId) => {
            navigate(`/wydarzenia/${eventId}/${newId}`);
          }}
        />
        <Gallery
          images={photos}
          imageConfig={(item) => {
            return {
              onClick: () => {
                navigate(`/wydarzenia/${item?.id}/${item?.photo_id}`);
              },
              src: `${process.env.REACT_APP_PHOTOS_PATH}/${item?.event_id}/${item?.event_id}_${item?.photo_id}.jpg`,
            };
          }}
        />
      </div>
    </div>
  );
};

export default SinglePost;
