import React, { useEffect, useState } from "react";
import Button from "components/Button/Button";
import "./FooterCartToolbar.scss";

const FooterCartToolbar = ({ selectedPhotosInfo }) => {
  console.log({ selectedPhotosInfo });
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const sum = selectedPhotosInfo.reduce((acc, photo) => acc + photo.price, 0);
    setTotalPrice(sum);
  }, [selectedPhotosInfo]);

  return (
    <div className="footer-cart-toolbar-module">
      <div className="price-wrapper">
        <div className="label">Cena produktów</div>
        <div className="price">{totalPrice.toFixed(2)} zł</div>
      </div>
      <div className="action-buttons">
        <Button variant="borderround">Wyczyść koszyk</Button>
        <Button variant="grayround">Przejdź do płatności</Button>
      </div>
    </div>
  );
};

export default FooterCartToolbar;
