import { Route, Routes } from "react-router-dom";
import Home from "pages/Home/Home";
import Categories from "pages/Categories/Categories";
import Login from "pages/LoginRegisterWrapper/Login/Login";
import Register from "pages/LoginRegisterWrapper/Register/Register";
import UserPanel from "pages/UserPanel/UserPanel";
import LoginRegisterWrapper from "pages/LoginRegisterWrapper";
import Footer from "modules/Footer/Footer";
import Posts from "./pages/Posts/Posts";
import SinglePost from "./pages/Posts/SinglePost/SingePost";
import Sponsors from "pages/Sponsors/Sponsors";
import Contact from "pages/Contact/Contact";
import Alert from "./modules/Alert/Alert";
import { useUser } from "hooks/useUsers";
import { useEffect } from "react";

const Root = () => {
  const { isLoggedIn } = useUser();

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <div>
      <Routes>
        <Route exact path="/kontakt" element={<Contact />} />
        <Route exact path="/sponsorzy" element={<Sponsors />} />
        <Route exact path="/wydarzenia/:eventId/" element={<SinglePost />} />
        <Route
          exact
          path="/wydarzenia/:eventId/:photoId"
          element={<SinglePost />}
        />
        <Route exact path="/dyscypliny" element={<Categories />} />
        <Route exact path="/dyscypliny/:id" element={<Categories />} />
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/logowanie"
          element={
            <LoginRegisterWrapper>
              <Login />
            </LoginRegisterWrapper>
          }
        />
        <Route
          exact
          path="/rejestracja"
          element={
            <LoginRegisterWrapper>
              <Register />
            </LoginRegisterWrapper>
          }
        />
        <Route exact path="/wydarzenia" element={<Posts />} />
        <Route exact path="/panel/*" element={<UserPanel />} />
      </Routes>
      <Footer />
      <Alert />
    </div>
  );
};

export default Root;
