import { useState, useEffect } from "react";
import "./ImageBox.scss";
import FavoritButton from "components/FavoritButton/FavoritButton";

const ImageBox = ({
  innerLeftTop,
  innerRightTop,
  outBottomRender,
  src,
  fullHover,
  infoBar,
  onClick,
}) => {
  const renderChild = (child) => (child ? child() : "");

  const [currentSrc, setCurrentSrc] = useState("loading_icon.gif");

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setCurrentSrc(src); // Ustawianie oryginalnego obrazu po załadowaniu
    };

    img.onerror = () => {
      setCurrentSrc("/placeholder.jpg"); // Ustawianie obrazu zastępczego w przypadku błędu
    };
  }, [src]);
  return (
    <div className="image-box-module">
      <div className="image-box-container">
        <div className="inner-left-top">{renderChild(innerLeftTop)}</div>
        <div className="inner-right-top">{renderChild(innerRightTop)}</div>
        <div className="image-container">
          <img src={currentSrc} onClick={onClick} />
          {fullHover && (
            <div className="full-hover">
              <div className="full-hover-container">
                <div className="favourite-button">
                  <FavoritButton item={{}} />
                </div>
                <h4>{fullHover.title}</h4>
                <h5>{fullHover.subtitle}</h5>
                <div onClick={fullHover.onClick} className="action-button">
                  {fullHover.buttonText}
                </div>
              </div>
            </div>
          )}
        </div>
        {infoBar && (
          <div
            className={`info-bar ${infoBar.allwaysShow ? "allways-show" : ""}`}
          >
            <div className="info-text">
              <h4>{infoBar.title}</h4>
              <h5>{infoBar.subtitle}</h5>
            </div>
            <div onClick={infoBar.onClick} className="action-button">
              {infoBar.buttonText}
            </div>
          </div>
        )}
      </div>
      <div className="out-bottom-render">{renderChild(outBottomRender)}</div>
    </div>
  );
};

export default ImageBox;
