import "../UserPanel.scss";
import "./Cart.scss";
import Gallery from "modules/Gallery/Gallery";
import OutBottomRender from "./components/OutBottomRender/OutBottomRender";
import { useNavigate } from "react-router-dom";
import FooterCartToolbar from "./components/FooterCartToolbar/FooterCartToolbar";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faSquare } from "@fortawesome/free-solid-svg-icons";

const Cart = ({ events: propsEvents, onRemove }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState(propsEvents);
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  useEffect(() => {
    setEvents(propsEvents);
    // Initialize selectedPhotos with all photos from propsEvents
    const allPhotos = propsEvents.flatMap((event) => {
      return event.photos.map((photo) => ({
        eventId: event.id,
        photoId: photo.photo_id,
        id: photo.id,
        categoryId: photo.category_id,
        price: event.price,
      }));
    });
    setSelectedPhotos(allPhotos);
  }, [propsEvents]);

  const togglePhotoSelection = (eventId, photoId) => {
    const isSelected = selectedPhotos.some(
      (photo) => photo.eventId === eventId && photo.photoId === photoId
    );

    if (isSelected) {
      setSelectedPhotos((prev) =>
        prev.filter(
          (photo) => !(photo.eventId === eventId && photo.photoId === photoId)
        )
      );
    } else {
      const event = propsEvents.find((event) => event.id === eventId);
      const photoData = event.photos.find(
        (photo) => photo.photo_id === photoId
      );
      setSelectedPhotos((prev) => [
        ...prev,
        {
          eventId: event.id,
          photoId: photoData.photo_id,
          id: photoData.id,
          categoryId: photoData.category_id,
          price: event.price,
        },
      ]);
    }
  };

  const getSelectedPhotosInfo = () => {
    return selectedPhotos.map(
      ({ eventId, photoId, id, categoryId, price }) => ({
        eventId,
        photoId,
        id,
        categoryId,
        price,
      })
    );
  };

  return (
    <div className="subpage cart-page">
      <div className="subpage-wrapper">
        <div className="subpage-header">
          <h2>Koszyk</h2>
        </div>
        <div className="subpage-content">
          {events.map((event) => (
            <Gallery
              title={event.title}
              onTitleClick={() => navigate(`/wydarzenia/${event.id}`)}
              images={event.photos || []}
              imageConfig={(item) => {
                const isSelected = selectedPhotos.some(
                  (photo) =>
                    photo.eventId === event.id &&
                    photo.photoId === item.photo_id
                );
                return {
                  onClick: () => {
                    navigate(`/wydarzenia/${item?.event_id}/${item?.photo_id}`);
                  },
                  src: `${process.env.REACT_APP_PHOTOS_PATH}/${item?.category_id}/${item?.category_id}_${item?.photo_id}.jpg`,
                  outBottomRender: () => (
                    <OutBottomRender
                      author={event.author}
                      price={event.price}
                      onRemove={() => onRemove(item)}
                    />
                  ),
                  innerRightTop: () => (
                    <div
                      className="check-action-button"
                      onClick={() =>
                        togglePhotoSelection(event.id, item.photo_id)
                      }
                    >
                      <FontAwesomeIcon
                        icon={isSelected ? faSquareCheck : faSquare}
                      />
                    </div>
                  ),
                };
              }}
            />
          ))}
          <FooterCartToolbar selectedPhotosInfo={getSelectedPhotosInfo()} />
        </div>
      </div>
    </div>
  );
};

export default Cart;
