import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./OutBottomRender.scss";

const OutBottomRender = ({ onRemove, author, price }) => {
  return (
    <div className="out-render-bottom-module">
      <div className="left-side">
        <div className="remove-button" onClick={onRemove}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
        {author}
      </div>
      <div className="right-side">Cena: {price} zł</div>
    </div>
  );
};

export default OutBottomRender;
