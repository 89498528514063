import "./CategoryMenu.scss";
import Button from "components/Button/Button";

const CategoryMenu = ({ config, onClick, activeCategoryId }) => {
  return (
    <div className="category-menu-module">
      {config?.map((menuItem) => (
        <Button variant={activeCategoryId === menuItem.id ? "round active-btn" : "round"} navigate={`/dyscypliny/${menuItem.id}`} key={menuItem.name} onClick={() => onClick(menuItem.id, menuItem.name)}>
          {menuItem.name}
        </Button>
      ))}
    </div>
  );
};

export default CategoryMenu;
