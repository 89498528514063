import React from "react";
import Header from "modules/Header/Header";
import Ambasador from "modules/Ambasador/Ambasador";
import Footer from "modules/Footer/Footer";
import { useState } from "react";
import HomeGalleryWrapper from "./HomeGalleryWrapper/HomeGalleryWrapper";

const Home = () => {
  const [popupState, setPopupState] = useState({
    isOpen: false,
    data: {},
  });

  return (
    <div className="home-page">
      <Header />
      <h1>Sponsorzy</h1>
      <Ambasador />
      <HomeGalleryWrapper />
      <Footer />
    </div>
  );
};

export default Home;
