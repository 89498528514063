import TwoColumns from "components/TwoColumn/TwoColumns";
import "./Ambasador.scss";

const Ambasador = () => {
  return (
    <div className="ambasador-module">
      <TwoColumns imagePath="ambasador.png">
        <h2>Ambasador naszej marki</h2>
        <h1>Grzegorz Kowalski</h1>
        <div className="text">
          <p>
            Pan Grzegorz Kowalski, jako ambasador marki rowerów, w pełni
            reprezentuje wartości i pasje związane z kolarstwem. Jego bogate
            doświadczenie jako wytrwałego kolarza oraz entuzjasty aktywnego
            trybu życia, sprawia, że jest inspirującym wzorem dla innych. Dzięki
            autentycznemu zaangażowaniu i zaawansowanemu wglądowi w technologie
            rowerowe, Pan Kowalski przyczynia się do wzmocnienia pozycji marki
            jako lidera w dziedzinie rowerów. Pan Jan Kowalski stanowi istotny
            filar marki rowerów, przyciągając uwagę swoją charyzmą i oddaniem
            dla kolarstwa. Jego udział w licznych zawodach i wydarzeniach
            rowerowych, wsparty pasją do eksploracji tras, dodaje autentyczności
            kampaniom marki. Jako ambasador, Pan Kowalski nie tylko podkreśla
            doskonałe osiągi rowerów, ale również łączy się z fanami kolarstwa
            poprzez swoje osobiste osiągnięcia i historie związane z
            pedałowaniem.
          </p>
        </div>
      </TwoColumns>
    </div>
  );
};

export default Ambasador;
