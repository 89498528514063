import { useState } from "react";
import "./Gallery.scss";
import ImageBox from "components/ImageBox/ImageBox";
import MultiselectToggler from "./components/MultiselectToggler";
import SelectedSummary from "./components/SelectedSummary";

const Gallery = ({
  isMultiselect = false,
  images,
  onBuy,
  onAddCart,
  imageConfig,
  title,
  onTitleClick,
}) => {
  const [isMultiselectActive, setMultiselectActive] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const isItemSelected = (id) => selectedImages.includes(id);
  const onSelectImage = (id) => {
    if (!isItemSelected(id)) {
      setSelectedImages([...selectedImages, id]);
    } else {
      setSelectedImages(selectedImages.filter((currentId) => currentId !== id));
    }
  };

  return (
    <div className="gallery-module">
      {title && <h2 onClick={onTitleClick}>{title}</h2>}
      {isMultiselect && (
        <MultiselectToggler
          isMultiselectActive={isMultiselectActive}
          setMultiselectActive={(value) => {
            setMultiselectActive(value);
            setSelectedImages([]);
          }}
        />
      )}
      <div className="gallery-content">
        {images?.map((item) => (
          <ImageBox {...imageConfig(item)} />
        ))}
      </div>

      {selectedImages.length ? (
        <SelectedSummary
          selectedImages={selectedImages}
          onAddCart={onAddCart || ""}
          onBuy={onBuy || ""}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Gallery;
