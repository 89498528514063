import { useContext } from "react";
import { CategoriesContext } from "../providers/CategoriesProvider";

export const useCategories = () => {
  const context = useContext(CategoriesContext);

  if (context === undefined) {
    throw new Error("usePosts was used outside of its provider");
  }

  const { categoriesData, setCategoriesData } = context;

  return { ...context, setCategoriesData, categoriesData };
};
