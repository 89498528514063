import "../Header/Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCartShopping,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { MENU_CONFIG } from "../Header/config";

import { Link } from "react-router-dom";

import Button from "components/Button/Button";
import Search from "components/Search/Search";
import Avatar from "components/Avatar/Avatar";

import { useUser } from "hooks/useUsers";

const SmallHeader = ({ isPanel = false }) => {
  const [menuVisable, setMenuVisable] = useState(false);
  const { userData, isLogged } = useUser();

  if (isPanel) {
    return (
      <div className="header-module small">
        <div className="container">
          {
            <div className={`mobile-menu${menuVisable ? " visible" : ""}`}>
              <div className="col-10 logo">
                <Link to="/">PhotoRace</Link>
              </div>
              <div className="burger-menu">
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => setMenuVisable(!menuVisable)}
                />
              </div>
              <div className="menu">
                <Search />
                {MENU_CONFIG.map((link) => (
                  <Link to={link.to}>{link.title}</Link>
                ))}

                <div className="button-container">
                  {isLogged ? <Avatar email={userData?.email} /> : <Button variant="round" navigate={"/logowanie"}>
                    Logowanie
                  </Button>}
                  {isLogged ? (
                    <Button
                      variant="fresh"
                      iconName={<FontAwesomeIcon icon={faHeart} />}
                      navigate={"/panel/ulubione"}
                    />
                  ) : (
                    <Button variant="fresh" navigate="/logowanie">
                      <FontAwesomeIcon icon={faHeart} />
                    </Button>
                  )}
                  {isLogged ? (
                    <Button
                      variant="fresh"
                      iconName={<FontAwesomeIcon icon={faCartShopping} />}
                      navigate="/panel/koszyk"
                    />
                  ) : (
                    <Button variant="fresh" navigate="/logowanie">
                      <FontAwesomeIcon icon={faCartShopping} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          }

          <div className="large-menu row">
            <div className="col-2 logo">
              <Link to="/">PhotoRace</Link>
            </div>
            <div className="col-10">
              <nav>
                {MENU_CONFIG.map((link) => (
                  <Button navigate={link.to} variant="fresh" key={link.to}>
                    {link.title}
                  </Button>
                ))}
                {isLogged ? (
                  <Button
                    variant="fresh"
                    iconName={<FontAwesomeIcon icon={faHeart} />}
                    navigate={"/panel/ulubione"}
                  />
                ) : (
                  <Button variant="fresh" navigate="/logowanie">
                    <FontAwesomeIcon icon={faHeart} />
                  </Button>
                )}
                {isLogged ? (
                  <Button
                    variant="fresh"
                    iconName={<FontAwesomeIcon icon={faCartShopping} />}
                    navigate="/panel/koszyk"
                  />
                ) : (
                  <Button variant="fresh" navigate="/logowanie">
                    <FontAwesomeIcon icon={faCartShopping} />
                  </Button>
                )}
                {isLogged ? <Avatar email={userData?.email} /> : <Button variant="round" navigate={"/logowanie"}>
                  Logowanie
                </Button>}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="header-module small">
      <div className="container">
        {
          <div className={`mobile-menu${menuVisable ? " visible" : ""}`}>
            <div className="col-10 logo">
              <Link to="/">PhotoRace</Link>
            </div>
            <div className="burger-menu">
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => setMenuVisable(!menuVisable)}
              />
            </div>
            <div className="menu">
              <Search />
              {MENU_CONFIG.map((link) => (
                <Link to={link.to}>{link.title}</Link>
              ))}
              
              <div className="button-container">
              {isLogged ? <Avatar email={userData?.email}/> : <Button variant="round" navigate={"/logowanie"}>
                Logowanie
              </Button>}
                {isLogged ? (
                  <Button
                    variant="fresh"
                    iconName={<FontAwesomeIcon icon={faHeart} />}
                    navigate={"/panel/ulubione"}
                  />
                ) : (
                  <Button variant="fresh" navigate="/logowanie">
                    <FontAwesomeIcon icon={faHeart} />
                  </Button>
                )}
                {isLogged ? (
                  <Button
                    variant="fresh"
                    iconName={<FontAwesomeIcon icon={faCartShopping} />}
                    navigate="/panel/koszyk"
                  />
                ) : (
                  <Button variant="fresh" navigate="/logowanie">
                    <FontAwesomeIcon icon={faCartShopping} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        }

        <div className="large-menu row">
          <div className="col-2 logo">
            <Link to="/">PhotoRace</Link>
          </div>
          <div className="col-10">
            <nav>
              {MENU_CONFIG.map((link) => (
                <Button navigate={link.to} variant="fresh" key={link.to}>
                  {link.title}
                </Button>
              ))}
              {isLogged ? (
                <Button
                  variant="fresh"
                  iconName={<FontAwesomeIcon icon={faHeart} />}
                  navigate={"/panel/ulubione"}
                />
              ) : (
                <Button variant="fresh" navigate="/logowanie">
                  <FontAwesomeIcon icon={faHeart} />
                </Button>
              )}
              {isLogged ? (
                <Button
                  variant="fresh"
                  iconName={<FontAwesomeIcon icon={faCartShopping} />}
                  navigate="/panel/koszyk"
                />
              ) : (
                <Button variant="fresh" navigate="/logowanie">
                  <FontAwesomeIcon icon={faCartShopping} />
                </Button>
              )}
              {isLogged ? <Avatar email={userData?.email}/> : <Button variant="round" navigate={"/logowanie"}>
                Logowanie
              </Button>}
            </nav>
          </div>
        </div>
        <div className="search-small">
          <Search />
        </div>
      </div>
    </div>
  );
};

export default SmallHeader;
