import { useFavorites } from "OdevFetch";
import { createContext, useEffect, useState } from "react";

export const FavoriteContext = createContext([]);

const FavoriteProvider = ({ children }) => {
  const { payload, loading, refetch, save, remove } = useFavorites();

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    if (JSON.stringify(favorites) !== JSON.stringify(payload?.data || [])) {
      setFavorites(payload?.data);
    }
  }, [payload, loading]);

  return (
    <FavoriteContext.Provider
      value={{
        favorites,
        setFavorites,
        loading,
        refetch,
        save,
        remove,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};

export default FavoriteProvider;
