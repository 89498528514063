import "../UserPanel.scss";
import "./Favorites.scss";
import Gallery from "modules/Gallery/Gallery";
import OutBottomRender from "./components/OutBottomRender/OutBottomRender";
import { useNavigate } from "react-router-dom";

const Favorites = ({ events, onRemove, onAddToCart }) => {
  const navigate = useNavigate();

  return (
    <div className="subpage cart-page">
      <div className="subpage-wrapper">
        <div className="subpage-header">
          <h2>Ulubione</h2>
        </div>
        <div className="subpage-content">
          {events.map((event) => (
            <Gallery
              title={event.title}
              images={event.photos || []}
              imageConfig={(item) => {
                return {
                  onClick: () => {
                    navigate(`/wydarzenia/${item?.event_id}/${item?.photo_id}`);
                  },
                  src: `${process.env.REACT_APP_PHOTOS_PATH}/${item?.category_id}/${item?.category_id}_${item?.photo_id}.jpg`,
                  outBottomRender: () => (
                    <OutBottomRender
                      author={event.author}
                      price={150}
                      onRemove={() => onRemove(item)}
                      onAddToCart={() => onAddToCart(item)}
                    />
                  ),
                };
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Favorites;
