import CategoryMenu from "modules/CategoryMenu/CategoryMenu";
import { useCategories } from "../../hooks/useCategories";
import SmallHeader from "modules/SmallHeader/SmallHeader";
import "./Categories.scss";
import { useState } from "react";
import CategoryGalleryWrapper from "./CategoryGalleryWrapper/CategoryGalleryWrapper";

const Categories = () => {
  const { categoriesData } = useCategories();
  const [categoryId, setCategoryId] = useState(categoriesData?.[0]?.id);
  const [categoryHeader, setCategoryHeader] = useState(
    categoriesData?.[0]?.name
  );

  const setCategoryAndActiveButton = (id, name) => {
    setCategoryHeader(name);
    setCategoryId(id);
  };

  return (
    <div className="categories-page">
      <SmallHeader />
      <div className="categories-wrapper">
        <h1>Dyscypliny</h1>
        <CategoryMenu
          config={categoriesData}
          onClick={setCategoryAndActiveButton}
          activeCategoryId={categoryId}
        />

        <CategoryGalleryWrapper
          categoryHeader={categoryHeader}
          categoryId={categoryId}
        />
      </div>
    </div>
  );
};

export default Categories;
