import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartFilled } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import { useFavorites } from "hooks/useFavorites";
import "./FavoritButton.scss";

const FavoritButton = ({ item }) => {
  const { favorites, save, remove, refetch } = useFavorites();
  const [favoriteId, setFavoritesId] = useState(null);

  useEffect(() => {
    if (item) {
      const currentFavoriteId = favorites.find(
        (favorite) =>
          favorite.photo_id == item.photoId && favorite.event_id == item.id
      )?.id;

      if (favoriteId !== currentFavoriteId) {
        setFavoritesId(currentFavoriteId);
      }
    }
  }, [favorites, item]);

  const toggleFavorite = async () => {
    if (favoriteId) {
      await remove({ id: favoriteId });
      await refetch();
    } else {
      await save({
        body: {
          photo_id: Number(item.photoId),
          event_id: Number(item.id),
          category_id: item.event_id,
        },
      });
      await refetch();
    }
  };

  return favoriteId ? (
    <FontAwesomeIcon
      className="favorite-button"
      icon={faHeartFilled}
      onClick={() => toggleFavorite()}
    />
  ) : (
    <FontAwesomeIcon
      className="favorite-button"
      icon={faHeart}
      onClick={() => toggleFavorite()}
    />
  );
};

export default FavoritButton;
