import "../styles.scss";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useUser } from "hooks/useUsers";
import { Formik, Field, Form } from "formik";

const Login = () => {
  const navigate = useNavigate();
  const { logIn } = useUser();

  const initialValues = {
    email: "",
    password: "",
  };

  const submitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const output = await logIn(values.email, values.password);
    if (output.status === "success") {
      navigate("/panel");
    }
    setSubmitting(false);
  };

  return (
    <div className="section-wrapper">
      <div className="panel">
        <div className="login-register-header">
          <h1>PhotoRace</h1>
          <Button variant="fresh" navigate="/">
            Powrót
          </Button>
        </div>
        <div className="form-wrapper">
          <p>Cieszymy się, że jesteś! Zaloguj się poniżej.</p>
          <div className="form">
            <Formik initialValues={initialValues} onSubmit={submitHandler}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Hasło</label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Hasło"
                    />
                  </div>
                  <div className="checkbox-wrapper">
                    <div className="save-password">
                      <div className="checkbox"></div>
                      <span>Zapamiętaj hasło</span>
                    </div>
                    <Button variant="fresh" navigate="przypomnij-haslo">
                      Nie pamiętasz hasła?
                    </Button>
                  </div>
                  <div className="submit-wrapper">
                    <div className="submit-content">
                      <Button type="submit" disabled={isSubmitting}>
                        Zaloguj
                      </Button>
                      <div>
                        <span>Nowy użytkownik? </span>
                        <Button variant="fresh" navigate="/rejestracja">
                          Zarejestruj się
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
