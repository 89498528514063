import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/Button/Button";
import ButtonGroup from "components/ButtonGroup/ButtonGroup";
import Avatar from "components/Avatar/Avatar";

import Search from "components/Search/Search";

import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCartShopping,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

import { MENU_CONFIG } from "./config";
import { useCategories } from "../../hooks/useCategories";
import { useUser } from "hooks/useUsers";
import { usePosts } from "OdevFetch";

const Header = () => {
  const { categoriesData: categories } = useCategories();
  const [menuVisable, setMenuVisable] = useState(false);
  const { userData, isLogged } = useUser();
  const { payload } = usePosts({ query: { per_page: 10 } });

  return (
    <div className="header-module">
      <div className="container">
        <div className={`mobile-menu${menuVisable ? " visible" : ""}`}>
          <div className="col-10 logo">
            <Link to="/">PhotoRace</Link>
          </div>
          <div className="burger-menu">
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => setMenuVisable(!menuVisable)}
            />
          </div>
          <div className="menu">
            <Search />
            {MENU_CONFIG.map((link) => (
              <Link to={link.to}>{link.title}</Link>
            ))}

            <div className="button-container">
              {isLogged ? (
                <Avatar email={userData?.email} />
              ) : (
                <Button variant="round" navigate={"/logowanie"}>
                  Logowanie
                </Button>
              )}
              {isLogged ? (
                <Button
                  variant="fresh"
                  iconName={<FontAwesomeIcon icon={faHeart} />}
                  navigate={"/panel/ulubione"}
                />
              ) : (
                <Button variant="fresh" navigate="/logowanie">
                  <FontAwesomeIcon icon={faHeart} />
                </Button>
              )}
              {isLogged ? (
                <Button
                  variant="fresh"
                  iconName={<FontAwesomeIcon icon={faCartShopping} />}
                  navigate="/panel/koszyk"
                />
              ) : (
                <Button variant="fresh" navigate="/logowanie">
                  <FontAwesomeIcon icon={faCartShopping} />
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="large-menu row">
          <div className="col-2 logo">
            <Link to="/">PhotoRace</Link>
          </div>
          <div className="col-10">
            <nav>
              {MENU_CONFIG.map((link) => (
                <Button navigate={link.to} variant="fresh">
                  {link.title}
                </Button>
              ))}
              {isLogged ? (
                <Button
                  variant="fresh"
                  iconName={<FontAwesomeIcon icon={faHeart} />}
                  navigate={"/panel/ulubione"}
                />
              ) : (
                <Button variant="fresh" navigate="/logowanie">
                  <FontAwesomeIcon icon={faHeart} />
                </Button>
              )}
              {isLogged ? (
                <Button
                  variant="fresh"
                  iconName={<FontAwesomeIcon icon={faCartShopping} />}
                  navigate="/panel/koszyk"
                />
              ) : (
                <Button variant="fresh" navigate="/logowanie">
                  <FontAwesomeIcon icon={faCartShopping} />
                </Button>
              )}
              {isLogged ? (
                <Avatar email={userData?.email} />
              ) : (
                <Button variant="round" navigate={"/logowanie"}>
                  Logowanie
                </Button>
              )}
            </nav>
          </div>
          <div className="col-12">
            <h1>
              Rowerowe emocje w każdym zdjęciu, pedaluj do zwycięstwa z każdym
              kadrem.
            </h1>

            <Search />
          </div>
          <div className="col-12 last-events-container">
            {payload?.map((event) => (
              <Button navigate={`wydarzenia/${event.id}`} variant="round">
                {event.title}
              </Button>
            ))}
          </div>
          <div className="categories-buttons">
            <ButtonGroup config={categories || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
