import { BrowserRouter } from "react-router-dom";
import "./vendor/normalize.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Root from "./Root";
import CategoriesProvider from "./providers/CategoriesProvider";
import UserProvider from "providers/UserProvider";
import FavoriteProvider from "providers/FavoriteProvider";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <CategoriesProvider>
          <FavoriteProvider>
            <BrowserRouter>
              <Root />
            </BrowserRouter>
          </FavoriteProvider>
        </CategoriesProvider>
      </UserProvider>
    </div>
  );
}

export default App;
