import "./PhotoPresentator.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "OdevFetch";
import FavoritButton from "components/FavoritButton/FavoritButton";

const PhotoPresentator = ({ post, photoId, onMoveItem }) => {
  const { save } = useCart({ isLazy: true });
  const navigate = useNavigate();

  const arrowHandler = (e) => {
    // e.preventDefault();
    // if (e.currentTarget.className === "arrow-next") {
    //   currentPhoto < images.length - 1 && setCurrentPhoto(currentPhoto + 1);
    // } else {
    //   currentPhoto >= 1 && setCurrentPhoto(currentPhoto - 1);
    // }
  };

  const backHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const [currentSrc, setCurrentSrc] = useState("loading_icon.gif");

  useEffect(() => {
    const img = new Image();
    img.src = `${process.env.REACT_APP_PHOTOS_PATH}/${post.event_id}/${post.event_id}_${photoId}.jpg`;
    img.onload = () => {
      setCurrentSrc(img.src); // Ustawianie oryginalnego obrazu po załadowaniu
    };

    img.onerror = () => {
      setCurrentSrc("placeholder.jpg"); // Ustawianie obrazu zastępczego w przypadku błędu
    };
  }, [photoId]);

  return (
    <div className="single-photo-module">
      <div className="wrapper">
        <div className="returner" onClick={(e) => backHandler(e)}>
          <Button
            iconName={<FontAwesomeIcon icon={faArrowLeft} />}
            variant="fresh"
          ></Button>
          {/* <span>{categoryName}</span> */}
        </div>
        <div className="photo-wrapper">
          <div className="arrow-back" onClick={(e) => arrowHandler(e)}>
            {photoId > 1 && (
              <Button
                iconName={<FontAwesomeIcon icon={faChevronLeft} />}
                variant="fresh"
                onClick={() => onMoveItem(Number(photoId) - 1)}
              ></Button>
            )}
          </div>
          <div className="photo">
            <span>
              <FavoritButton item={{ ...post, photoId }} />
            </span>
            <img src={currentSrc} alt="example" />
          </div>
          <div className="arrow-next" onClick={(e) => arrowHandler(e)}>
            {photoId < post.image_count && (
              <Button
                iconName={<FontAwesomeIcon icon={faChevronRight} />}
                variant="fresh"
                onClick={() => onMoveItem(Number(photoId) + 1)}
              ></Button>
            )}
          </div>
        </div>
        <div className="content-wrapper">
          <div className="header-wrapper">
            <div className="header-text">
              <h1>{post?.title}</h1>
              <div className="header-author">
                <span>{post?.author}</span>
                <span>{post?.date}</span>
              </div>
            </div>
            <div className="header-btns">
              <Button
                onClick={async () => {
                  await save({
                    body: {
                      photo_id: 1,
                      event_id: Number(post.id),
                      category_id: post.event_id,
                    },
                  });
                }}
              >
                Dodaj do koszyka
              </Button>
              <Button onClick={() => console.log("Kupiono teraz")}>
                Kup teraz
              </Button>
            </div>
          </div>
          <div
            className="text-wrapper"
            dangerouslySetInnerHTML={{ __html: post.content }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PhotoPresentator;
