import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./SideMenu.scss";

const SideMenu = ({ items }) => {
  const [isShowMore, setShowMore] = useState(false);

  const basicItems = items.slice(0, 4);
  const moreItems = items.slice(4);

  const renderItem = (item) => (
    <NavLink key={item.path} to={item.path} className="nav-item">
      <i className={`fa ${item.icon} mr-2`} aria-hidden="true"></i>
      <p>{item.title}</p>
    </NavLink>
  );

  return (
    <div className="side-menu-module">
      <div className="basic">
        {basicItems.map(renderItem)}

        {/* <div
          className="more-button nav-item"
          onClick={() => setShowMore(!isShowMore)}
        >
          <i className="fas fa-ellipsis-v"></i>
          więcej
        </div> */}
      </div>
      {/* <div className={`more ${isShowMore && "show"}`}>
        {moreItems.map(renderItem)}
      </div> */}
    </div>
  );
};

export default SideMenu;
