const MultiselectToggler = ({ isMultiselectActive, setMultiselectActive }) => {
  return (
    <div className="gallery-header">
      <div className="multiselect">
        <label id="multiselect">Multizaznaczenie</label>
        <input
          type="checkbox"
          htmlFor="multiselect"
          checked={isMultiselectActive}
          onChange={(e) => setMultiselectActive(e.target.checked)}
        />
      </div>
    </div>
  );
};

export default MultiselectToggler;
