import "./Avatar.scss";
import { Link } from "react-router-dom";

const Avatar = ({ email }) => {
  const initials = email?.split("")[0]?.toUpperCase();
  return (
    <Link className="avatar-component" to="/panel/konto">
      {initials}
    </Link>
  );
};

export default Avatar;
