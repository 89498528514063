import React from "react";
import Button from "../Button/Button";
import "./ButtonGroup.scss";

const ButtonGroup = ({ config, className }) => {
  return (
    <div
      className={`btn-group ${className}`}
      role="group"
      aria-label="Basic example"
    >
      {config.map((buttonConfig) => (
        <Button
          key={buttonConfig.name}
          navigate={`/dyscypliny/${buttonConfig.id}`}
        >
          {buttonConfig.name}
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroup;
