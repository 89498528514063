import "./Sponsors.scss";
import SmallHeader from "modules/SmallHeader/SmallHeader";

const Sponsors = () => {

  return (
    <div className="sponsors-page">
      <SmallHeader />
      <div className="sponsors-wrapper">
        <h2>Sponsorzy</h2>
      </div>
    </div>
  )
};

export default Sponsors;