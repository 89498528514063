export const socialMedia = [
  {
    name: "Facebook",
    text: "Połącz z facebookiem",
    href: "#",
    src: "/socialmedia/facebook.png"
  },
  {
    name: "Instagram",
    text: "Połącz z instagramem",
    href: "#",
    src: "/socialmedia/instagram.png"
  }
];