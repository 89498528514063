import { createContext, useState, useEffect } from "react";
import { useCategories } from "../OdevFetch";
import { pick } from "lodash";

export const CategoriesContext = createContext({});

const CategoriesProvider = ({ children }) => {
  const { payload, loading } = useCategories();
  const [categoriesData, setCategoriesData] = useState();

  useEffect(() => {
    const filteredCategories = payload?.map((item) =>
      pick(item, ["id", "description", "name"])
    );
    setCategoriesData(filteredCategories);
  }, [loading]);

  return (
    <CategoriesContext.Provider value={{ categoriesData, setCategoriesData }}>
      {!!categoriesData && categoriesData.length ? children : "Loading"}
    </CategoriesContext.Provider>
  );
};

export default CategoriesProvider;
