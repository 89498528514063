import Gallery from "modules/Gallery/Gallery";
import { usePosts } from "OdevFetch";
import { useNavigate } from "react-router-dom";

const PostsGalleryWrapper = () => {
  const navigate = useNavigate();

  const { payload, refetch, loading } = usePosts({
    query: { per_page: 20 },
  });

  if (loading) {
    return "Loadig-posts";
  }

  const postsGallery = payload.map((item) => ({
    title: item.title,
    author: item.author,
    alt: item.title,
    id: item.id,
    src: `https://photorace-api.odev.pl/zdjecia/${item.event_id}/${item.event_id}_1.jpg`,
  }));

  return (
    <Gallery
      images={postsGallery}
      imageConfig={(item) => ({
        src: item?.src,
        fullHover: {
          title: item.title,
          subtitle: item.author,
          buttonText: "Więcej",
          onClick: () => {
            navigate(`/wydarzenia/${item.id}`);
          },
        },
      })}
    />
  );
};

export default PostsGalleryWrapper;
