import { useContext } from "react";
import { UserContext } from "providers/UserProvider";
import { fetchPayload } from "OdevFetch/fetchPayload";
import { fetchSetting } from "OdevFetch/fetchConfig";

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser was used outside of its Provider");
  }
  const {
    isLogged,
    setLogged,
    setUserData,
    userData,
    favorites,
    setFavorites,
  } = context;

  const isLoggedIn = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = sessionStorage.getItem("refreshToken");

    if (accessToken) {
      const output = await fetch(
        `${process.env.REACT_APP_API_PATH}/isLogged/${
          accessToken.split(" ")[1]
        }`,
        {
          ...fetchSetting,
          method: "GET",
          headers: {
            ...fetchSetting.headers,
            ["Access-Token"]: `${accessToken}`,
          },
        }
      ).then((res) => res.json());

      setLogged(true);
      setUserData({ ...output.data, accessToken, refreshToken });
    }
  };

  const logIn = async (login, password) => {
    let data = null;

    await fetchPayload({
      endpoint: "login",
      body: { email: login, password },
      callback: (payload) => {
        data = payload;
      },
    });

    if (data.status === "success") {
      sessionStorage.setItem("accessToken", data.body.accessToken);
      sessionStorage.setItem("refreshToken", data.body.refreshToken);
      document.showAlert("success", "Sukces", data.message);
      setLogged(true);
      setUserData({ ...data.body });
      return { status: data.status };
    } else if (data.error) {
      return { error: data.error };
    }

    if (data?.status === "error") {
      document.showAlert("error", "", data.message);
    }

    return data;
  };

  const logOut = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    setUserData({});
    setLogged(false);
  };

  return {
    isLogged,
    logIn,
    logOut,
    userData,
    isLoggedIn,
    favorites,
    setFavorites,
  };
};
