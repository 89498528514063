import { useQuery } from "./useQuery";
import { returnFetch } from "./returnFetch";
import { pick } from "lodash";

const getQuery = (query) => {
  const values = [];
  if (query) {
    Object.keys(query).forEach((key) => {
      values.push(`${key}=${query[key]}`);
    });
  }
  const preparedQuery = values.join("&");

  return values.length ? `?${preparedQuery}` : "";
};

const endpointGenerator = (endpoint, props, rest) => {
  let preparedEndpoint =
    endpoint === "posts" ||
    endpoint === "categories" ||
    endpoint.startsWith("posts/")
      ? `${process.env.REACT_APP_WORDPRESS_API_PATH}/${endpoint}`
      : `${process.env.REACT_APP_API_PATH}/${endpoint}/`;

  if (props?.id) {
    preparedEndpoint += `/${props.id}/`;
  }
  if (rest) {
    preparedEndpoint += `/${rest}`;
  }
  if (props?.query) {
    preparedEndpoint += getQuery(props.query);
  }

  return preparedEndpoint;
};

export const usePosts = (props) => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("posts", props),
    isLazy: props?.isLazy,
  });

  const filteredPayload = Array.isArray(payload)
    ? payload.map((item) => ({
        ...pick(item, ["id", "content"]),
        category: item?.categories?.[0] || null,
        ...item.acf,
        image_count: Number(item?.acf?.image_count || 0),
        price: Number(item?.acf.price || 0),
        title: item?.title?.rendered,
        content: item?.content?.rendered,
      }))
    : [];

  const customeRefetch = (props) => {
    refetch({ endpoint: endpointGenerator("posts", props) });
  };

  return {
    loading,
    payload: filteredPayload,
    refetch: customeRefetch,
  };
};

export const usePost = (props) => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator(`posts`, props),
    isLazy: props?.isLazy,
  });

  const filteredPayload = {
    ...payload?.acf,
    content: payload?.content?.rendered || "(Opis jest pusty)",
    id: payload?.id,
    title: payload?.title?.rendered || "",
    image_count: Number(payload?.acf?.image_count || 0),
    price: Number(payload?.acf.price || 0),
    author: payload?.acf?.author || "",
    category: payload?.categories?.[0] || null,
  };

  return {
    loading,
    payload: filteredPayload,
    refetch,
  };
};

export const useCategories = (props) => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("categories", props),
    isLazy: props?.isLazy,
  });

  return {
    loading,
    payload,
    refetch,
  };
};

export const useUsers = (props) => {
  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "register", body });
    return data;
  };

  const login = async ({ body }) => {
    const data = await returnFetch({ endpoint: "login", body });
    return data;
  };

  return {
    save,
    login,
  };
};

export const useCart = (props) => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("cart", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "cart", body });
    return data;
  };

  const reset = async () => {
    const data = await returnFetch({ endpoint: `cart/reset` });
    return data;
  };

  const remove = async ({ id }) => {
    const data = await returnFetch({ endpoint: `cart/${id}/remove` });
    return data;
  };

  return {
    save,
    reset,
    remove,
    loading,
    payload,
    refetch,
  };
};

export const useFavorites = (props) => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("favorites", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "favorites", body });
    return data;
  };

  const remove = async ({ id }) => {
    const data = await returnFetch({ endpoint: `favorites/${id}/remove` });
    return data;
  };

  return {
    save,
    remove,
    loading,
    payload,
    refetch,
  };
};

export const useLibrary = (props) => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("library", props),
    isLazy: props?.isLazy,
  });

  return {
    loading,
    payload,
    refetch,
  };
};
