import SmallHeader from "modules/SmallHeader/SmallHeader";
import "./UserPanel.scss";
import SideMenu from "modules/SideMenu/SideMenu";
import { Routes, Route } from "react-router-dom";

import CartLoader from "./Cart/CartLoader";
import FavoritesLoader from "./Favorites/FavoritesLoader";
import Library from "./Library/Library";
import EditPanel from "./EditPanel/EditPanel";
import Search from "components/Search/Search";

import { GALLERY } from "modules/Gallery/config";

import { useUser } from "hooks/useUsers";

const UserPanel = () => {
  const { userData } = useUser();

  return (
    <div className="user-panel-page">
      <SmallHeader isPanel={true} />
      <div className="user-panel-wrapper">
        <div className="user-panel-header">
          <h2>Witaj {userData.email}</h2>
          <Search />
        </div>
        <SideMenu
          items={[
            { path: "koszyk", title: "Koszyk", icon: "fa-shopping-cart" },
            { path: "biblioteka", title: "Biblioteka", icon: "fa-folder-open" },
            { path: "ulubione", title: "Ulubione", icon: "fa-heart" },
            { path: "konto", title: "Konto", icon: "fa-user" },
          ]}
        />
        <div className="user-panel-content">
          <Routes>
            <Route exact path="/koszyk" element={<CartLoader />} />
            <Route exact path="/ulubione" element={<FavoritesLoader />} />
            <Route
              exact
              path="/biblioteka"
              element={<Library gallery={GALLERY} />}
            />
            <Route exact path="/konto" element={<EditPanel />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserPanel;
