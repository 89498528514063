export const MENU_CONFIG = [
  {
    title: "Strona Główna",
    to: "/",
  },
  {
    title: "Dyscypliny",
    to: "/dyscypliny",
  },
  {
    title: "Wydarzenia",
    to: "/wydarzenia",
  },
  {
    title: "Sponsorzy",
    to: "/sponsorzy",
  },
  {
    title: "Kontakt",
    to: "/kontakt",
  },
];

export const LAST_EVENTS = [
  {
    title: "Górski Maraton Rowerowy",
    to: "https://przykladowa-strona.pl/gorski-maraton",
  },
  {
    title: "Nocna Wyprawa Rowerowa",
    to: "https://przykladowa-strona.pl/nocna-wyprawa",
  },
  {
    title: "Międzymiastowy Rajd Rowerowy",
    to: "https://przykladowa-strona.pl/miedzymiastowy-rajd",
  },
  {
    title: "Zawody XC",
    to: "https://przykladowa-strona.pl/zawody-xc",
  },
  {
    title: "Dzień Otwartych Drzwi w Bike Parku",
    to: "https://przykladowa-strona.pl/bike-park",
  },
];

export const CATEGORIES = [
  {
    title: "Kolarstwo torowe",
    to: "/",
  },
  {
    title: "Kolarstwo górskie",
    to: "/",
  },
  {
    title: "Kolarstwo szosowe",
    to: "/",
  },
  {
    title: "Kolarstwo BMX",
    to: "/",
  },
  {
    title: "Kolarstwo downhillowe",
    to: "/",
  },
  {
    title: "Kolarstwo miejskie",
    to: "/",
  },
  {
    title: "Kolarstwo cyklokrosowe",
    to: "/",
  },
];
