import { useEffect, useState } from "react";
import Favorites from "./Favorites";
import { useCart, usePosts } from "OdevFetch";
import { useFavorites as useFavoritesContext } from "hooks/useFavorites";

const FavoritesLoader = () => {
  const [events, setEvents] = useState([]);
  const [favoritesLoaded, setFavoritesLoaded] = useState(false);

  const {
    favorites: favoritesPayload,
    loading: favoritesLoading,
    remove: favoritesRemove,
    refetch: favoritesRefetch,
  } = useFavoritesContext();

  const { save: cartSave } = useCart({
    isLazy: true,
  });

  const {
    payload: postPayload,
    loading: postLoading,
    refetch: postRefetch,
  } = usePosts({
    isLazy: true,
  });

  // if favoritesLoading is finish, find posts
  useEffect(() => {
    if (!favoritesLoading) {
      const uniqueEventIds = new Set();
      if (favoritesPayload) {
        favoritesPayload.forEach((obj) => {
          uniqueEventIds.add(obj.event_id);
        });

        const uniqIds = Array.from(uniqueEventIds);

        if (uniqIds.length) {
          postRefetch({
            query: {
              include: uniqIds.join(","),
            },
          });
        }
      }
    }
  }, [favoritesLoading, favoritesPayload]);

  // if post is loaded, set events
  useEffect(() => {
    if (!postLoading && !favoritesLoading) {
      postPayload.forEach((post) => {
        post.photos = favoritesPayload.filter(
          (favorites) => favorites.event_id === post.id
        );
      });
      setEvents(postPayload);
    }
  }, [postLoading]);

  const onRemoveHandler = async (item) => {
    await favoritesRemove({ id: item.id });
    await favoritesRefetch();
  };

  const onAddToCartHandler = async (item) => {
    await cartSave({
      body: {
        photo_id: item.photo_id,
        event_id: item.event_id,
        category_id: item.category_id,
      },
    });

    await favoritesRemove({ id: item.id });
    await favoritesRefetch();
  };

  if (favoritesLoading || postLoading) {
    return (
      <div>
        <img className="loading" src="/loading.png" />
      </div>
    );
  }

  if (favoritesPayload.length == 0) {
    return <div>Brak ulubionych zdjęć</div>;
  }

  return (
    <Favorites
      events={events}
      onRemove={onRemoveHandler}
      onAddToCart={onAddToCartHandler}
    />
  );
};

export default FavoritesLoader;
