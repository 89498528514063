import "./Posts.scss";
import SmallHeader from "modules/SmallHeader/SmallHeader";
import PostsGalleryWrapper from "./PostsGalleryWrapper/PostsGalleryWrapper";

const Posts = () => {
  return (
    <div className="posts-page">
      <SmallHeader />
      <div className="posts-wrapper">
        <h2>Wydarzenia</h2>
        <PostsGalleryWrapper />
      </div>
    </div>
  );
};

export default Posts;
