import { useContext } from "react";
import { FavoriteContext } from "../providers/FavoriteProvider";

export const useFavorites = () => {
  const context = useContext(FavoriteContext);

  if (context === undefined) {
    throw new Error("useFavorites was used outside of its provider");
  }

  const { favorites, setFavorites } = context;

  return { ...context, favorites, setFavorites };
};
