import Gallery from "modules/Gallery/Gallery";
import { useNavigate } from "react-router-dom";
import { usePosts } from "OdevFetch";

const HomeGalleryWrapper = () => {
  const navigate = useNavigate();
  const { payload, loading } = usePosts({
    query: { per_page: 20 },
  });

  const postsGallery = payload.map((item) => ({
    title: item.title,
    author: item.author,
    alt: item.title,
    id: item.id,
    src: `https://photorace-api.odev.pl/zdjecia/${item.event_id}/${item.event_id}_1.jpg`,
  }));

  return (
    <Gallery
      title="Nowe wydarzenia"
      images={postsGallery}
      imageConfig={(item) => {
        return {
          src: item?.src,
          fullHover: {
            allwaysShow: true,
            title: item.title,
            subtitle: item.author,
            buttonText: "Więcej",
            onClick: () => {
              navigate(`/wydarzenia/${item.id}`);
            },
          },
        };
      }}
    />
  );
};

export default HomeGalleryWrapper;
