import "./styles.scss";
import Button from "components/Button/Button";
import { useUser } from "hooks/useUsers";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginRegisterWrapper = ({ children }) => {
  const { isLogged } = useUser();
  const navigate = useNavigate();
  
  useEffect(() => {
    isLogged && navigate("/panel");
  }, []);

  return (
    <div className="login-register-page">
      <div className="wrapper">
        <div className="right-section">
          {children}
          <div className="sponsor-btns">
            <Button>Sponsor</Button>
            <Button>Sponsor</Button>
            <Button>Sponsor</Button>
          </div>
        </div>
        <div className="side-graphic">
          <img src="layout/elipse.png" alt="elipse" className="elipse" />
          <img
            src="layout/rectangle.png"
            alt="rectangle"
            className="rectangle"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterWrapper;
